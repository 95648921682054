<template>
    <div v-if="(gatewaysDown && gatewaysDown.length > 0 || beaconsOutCoverage && beaconsOutCoverage.length > 0) && pSite && pSite.isInProduction && hasAccess(siteId, 'SITE_CONFIGURATION.GATEWAY') && hasAccess(siteId, 'SITE_CONFIGURATION.AUTOCALIBRATION_TAG')" class="kt-portlet kt-portlet--height-fluid" style=" border: 2px solid #f9397a; ">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill kt-font-danger" viewBox="0 0 16 16">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                </span>
                <h3 class="kt-portlet__head-title kt-font-danger">
                    {{ $t("site_siteActionRequiredTitle") }}
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-widget28">
                <div class="kt-widget28__wrapper">
                    <!-- begin::Tab Content -->
                    <div class="tab-content kt-margin-t-0">
                        <div id="menu12" class="tab-pane active" style=" max-height: 500px; overflow: auto; padding-right: 10px; ">
                            <div class="kt-widget4">
                                <div v-for="gateway in gatewaysDown" :key="gateway.id" class="kt-widget4__item">
                                    <div v-if="gateway.building && gateway.floor && pBuildingsAndFloors && pBuildingsAndFloors.length > 0" style=" display: contents; ">
                                        <span class="kt-widget4__icon">
                                            <i class="flaticon2-information kt-font-danger"></i>
                                        </span>
                                        <div class="kt-widget4__info">
                                            <span class="kt-font-danger font-weight-bold"> {{ $t("site_gatewayIsDown", { gatewayName: gateway.name, gatewayMac: gateway.bleMacAddress }) }}</span>
                                            <p class="kt-widget4__text" style="font-weight: 400; color: #393940;">{{ $t("site_siteActionRequiredGatewayMessage", { buildingName: getBuildingName(gateway.building), floorName: getFloorName(gateway.floor)}) }}</p>
                                        </div>
                                        <router-link v-if="!user.isTSUser" :to="{ name: 'gatewaysLocation', params: { siteId: siteId, highlight: gateway.id } }" class="btn btn-sm btn-label-brand btn-bold">
                                            {{ $t("site_siteActionRequiredGatewayLocation") }}
                                        </router-link>
                                        <router-link :to="{ name: 'siteGatewaysHealth' }" class="btn btn-sm btn-label-brand btn-bold ml-2">
                                            {{ $t("site_siteActionRequiredGatewayAnalyse") }}
                                        </router-link>
                                    </div>
                                </div>
                                <div v-for="tag in beaconsOutCoverage" :key="tag.id" class="kt-widget4__item">
                                    <div v-if="tag.building && tag.floor && pBuildingsAndFloors && pBuildingsAndFloors.length > 0" style=" display: contents; ">
                                        <span class="kt-widget4__icon">
                                            <i class="flaticon2-information kt-font-danger"></i>
                                        </span>
                                        <div class="kt-widget4__info">
                                            <span class="kt-font-danger font-weight-bold"> {{ $t("site_beacontagIsDown", { serialNumber: tag.serialNumber, tagMac: tag.macAddress }) }}</span>
                                            <p class="kt-widget4__text" style="font-weight: 400; color: #393940;">{{ $t("site_siteActionRequiredGatewayMessage", { buildingName: getBuildingName(tag.building), floorName: getFloorName(tag.floor)}) }}</p>
                                        </div>
                                        <router-link v-if="!user.isTSUser" :to="{ name: 'autocalibrationtagsLocation', params: { siteId: siteId, highlight: tag.id } }" class="btn btn-sm btn-label-brand btn-bold">
                                            {{ $t("site_siteActionRequiredBeaconLocation") }}
                                        </router-link>
                                        <router-link :to="{ name: 'siteBeaconsHealth' }" class="btn btn-sm btn-label-brand btn-bold ml-2">
                                            {{ $t("site_siteActionRequiredBeaconAnalyse") }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Tab Content -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            gatewaysDown: [],
            beaconsOutCoverage: []
        };
    },
    created: function() {
        console.log("Component(SiteActionRequired)::created() - called with id: " + this.siteId);
        this.getSiteGatewaysHealth(this.siteId);
        this.getSiteTagsHealth(this.siteId);
    },
    mounted: function() {
        console.log("Component(SiteActionRequired)::mounted() - Init metronic layout");
        this.updateScrollbar();
    },
    destroyed: function() {
        console.log("Component(SiteActionRequired)::destroyed() - called");
        this.resetSiteHealthState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteGatewaysHealth: {
            immediate: true,
            handler(gatewaysHealth) {
                console.log("Component(SiteActionRequired)::watch(siteGatewaysHealth) called");
                this.gatewaysDown = [];
                if (gatewaysHealth && gatewaysHealth.length > 0) {
                    for (let gateway of gatewaysHealth) {
                        if (gateway.building && gateway.floor){ // Work only on gateway which are located on a floor
                            if (!gateway.lastEmission || gateway.lastEmission === -1) {
                                this.gatewaysDown.push(gateway);
                            }
                        }
                    }
                } else {
                    this.gatewaysDown = [];
                }
            }
        },

        siteTagsHealth: {
            immediate: true,
            handler(tagsHealth) {
                console.log("Component(SiteActionRequired)::watch(siteGatewaysHealth) called");
                this.beaconsOutCoverage = [];
                if (tagsHealth && tagsHealth.length > 0) {
                    for (let tag of tagsHealth) {
                        if (tag.building && tag.floor){
                            if (!tag.coverage || tag.coverage.length === 0) {
                                this.beaconsOutCoverage.push(tag);
                            }
                        }
                    }
                } else {
                    this.beaconsOutCoverage = [];
                }
            }
        }
    },
    props: {
        pBuildingsAndFloors: {
            type: Array
        },

        pSite: {
            type: Object
        },

        pAutoCalibrationTags: {
            type: Array
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteGatewaysHealth", "siteTagsHealth", "user", "hasAccess"]),

    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteGatewaysHealth", "getSiteTagsHealth", "resetSiteHealthState"]),

        updateScrollbar() {
            let actListScrollbar = $("#siteElementStatus");
            let offset = 185;
            if (actListScrollbar) {
                let offset = 185;
                let mapHeight = $("#map").height();
                actListScrollbar.attr("data-height", $("#siteElementStatus").height() - offset);
                KTApp.init();
                KTLayout.init();
            }
        },

        // Return the name of building by its id or his body
        getBuildingName(building) {
            if (building && building.id && building.name) {
                // it is object return the name
                return building.name;
            } else {
                const buildingId = building;
                let findBuilding = null;
                findBuilding = _.find(this.pBuildingsAndFloors, (buiding) => {
                    if (buiding.id === buildingId) {
                        return buiding;
                    }
                });

                return findBuilding.name || "";
            }
        },

        // Return the name of floor by its id or his body
        getFloorName(floor) {
            if (floor && floor.id && floor.name) {
                // it is object return the name
                return floor.name;
            } else {
                const floorId = floor;
                let findFloor = null;
                for (let building of this.pBuildingsAndFloors) {
                    findFloor = _.find(building.floors, (floor) => {
                        if (floor.id === floorId) {
                            return floor
                        }
                    });
                    if (findFloor && findFloor.name) {
                        break;
                    }
                }

                return findFloor.name || "";
            }
        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.wordWrap {
    word-wrap: break-word;
}
</style>
