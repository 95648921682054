<template>
    <div id="siteOverview" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="overview"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="site_editSiteSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div v-if="isBeaconOALBTExists" class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon"><i class="flaticon-warning kt-font-warning"></i></div>
                                    <div class="alert-text kt-font-warning kt-font-bold">
                                        <span>{{ $t("act_warningBLEBeacon") }}</span>
                                    </div>
                                </div>
                            </div>

                            <div v-if="isTagBatteryCritical && hasAccess(siteId, 'SITE_CONFIGURATION.GATEWAY') && hasAccess(siteId, 'SITE_CONFIGURATION.AUTOCALIBRATION_TAG')" class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-custom alert-outline-danger alert-light fade show" role="alert" style="font-weight: 500;">
                                    <div class="alert-icon"><i class="flaticon-warning"></i></div>
                                    <div class="alert-text">{{ $t("tag_batteryCriticalMessage") }}</div>
                                </div>
                            </div>

                            <div v-if="isTagBatteryLow && hasAccess(siteId, 'SITE_CONFIGURATION.GATEWAY') && hasAccess(siteId, 'SITE_CONFIGURATION.AUTOCALIBRATION_TAG')" class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-custom alert-outline-warning alert-light fade show" role="alert" style="font-weight: 500;">
                                    <div class="alert-icon"><i class="flaticon-warning"></i></div>
                                    <div class="alert-text">{{ $t("tag_batteryLowMessage") }}</div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-xl-6">

                                    <div class="row">
                                        <div v-if="this.currentSite && this.currentSite.location && this.currentSite.isInProduction" class="col-xl-12">
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <app-siteactionrequired v-bind:pSite="currentSite" v-bind:pBuildingsAndFloors="siteBuildings" v-bind:pAutoCalibrationTags="siteAutoCalibrationTags"></app-siteactionrequired>
                                                </div>
                                                <div class="col-xl-12">
                                                    <app-markerlocation v-bind:pSite="currentSite"></app-markerlocation>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-12">
                                            <app-countersoverview
                                                v-bind:pSite="currentSite"
                                                v-bind:pSiteBuildings="siteBuildings"
                                                v-bind:pSiteIndoorAreas="siteIndoorAreas"
                                                v-bind:pSiteGeofences="siteGeofences"
                                                v-bind:pSiteWalls="siteWalls"
                                                v-bind:pSiteBLEGateways="siteBLEGateways"
                                                v-bind:pSiteTags="siteTags"
                                                v-bind:pTotalNumberOfFloors="totalNumberOfFloors"
                                                v-bind:pSiteAutoCalibrationTags="siteAutoCalibrationTags"
                                                v-bind:pSiteAssets="siteAssets"
                                                v-bind:pSiteNotifications="siteGeonotifications"
                                            ></app-countersoverview>
                                        </div>
                                        <div v-if="this.currentSite && this.currentSite.location && !this.currentSite.isInProduction" class="col-xl-12">
                                            <app-markerlocation v-bind:pSite="currentSite"></app-markerlocation>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-xl-6">

                                    <div class="row">
                                        <div class="col-xl-12">
                                            <app-checklist
                                                v-if="hasFullConfigAccess(siteId)"
                                                v-bind:pSite="currentSite"
                                                v-bind:pSiteBuildings="siteBuildings"
                                                v-bind:pSiteIndoorAreas="siteIndoorAreas"
                                                v-bind:pSiteBLEGateways="siteBLEGateways"
                                                v-bind:pSiteTags="siteTags"
                                                v-bind:pSiteAutoCalibrationTags="siteAutoCalibrationTags"
                                                v-bind:pSiteAssets="siteAssets"
                                            ></app-checklist>
                                        </div>
                                        <div class="col-xl-12">
                                            <app-sitemetadata v-bind:pSite="currentSite"></app-sitemetadata>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SiteMetadata from "./widgets/sitemetadata.vue";
import SiteCountersOverview from "./widgets/countersoverview.vue";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "./widgets/sitetitle.vue";
import CheckList from "./widgets/checklist.vue";
import MarkerLocation from "./widgets/markerlocation.vue";
import SiteActionRequired from './widgets/siteactionrequired';

export default {
    name: "siteoverview",
    data() {
        return {
            siteId: this.$route.params.siteId,
            isTagBatteryLow: false,
            isTagBatteryCritical: false,
            isBeaconOALBTExists: false
        };
    },
    created: function() {
        console.log("Component(SiteOverview)::created() - called");
        let siteId = this.$route.params.siteId;
        // Load site info
        this.getSiteById(siteId);
        // Load buildings to get number of buildings associated to the site
        this.getSiteBuildingsAndTheirFloors(this.siteId);
        // Load indoor areas
        this.getSiteIndoorAreas(siteId);
        // Load geofences
        this.getSiteGeofences(siteId);
        // Load walls
        this.getSiteWalls(siteId);
        // Load gateways to get number of gateways associated to the site
        this.getSiteBLEGateways(this.siteId);
        // Load tags to get number of tags associated to the site
        this.getSiteTags(this.siteId);
        // Load autocalibration tags
        this.getSiteAutoCalibrationTags(this.siteId);
        // Load assets
        this.getSiteAssets(this.siteId);
        // Load site geonotifications
        this.getSiteGeonotifications(this.siteId);
    },
    mounted: function() {
        console.log(
            "Component(SiteOverview)::mounted() - Init metronic layout"
        );
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(SiteOverview)::destroyed() - called");
    },
    beforeRouteLeave(to, from, next) {
        console.log("Component(SiteOverview)::beforeRouteLeave() - called");
        this.resetAssetsState();
        this.resetAutocalibrationTagsState();
        this.resetTagsState();
        this.resetGatewaysState();
        this.resetIndoorAreasState();
        this.resetGeofencesState();
        this.resetWallsState();
        this.resetBuildingsState();
        this.resetSitesState();
        next();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        user: function() {
            console.log("Component(Siteoverview)::watch(user) called");
        },

        siteAutoCalibrationTags(tags) {
            console.log("Component(Siteoverview)::watch(siteAutoCalibrationTags) called ", tags);
            if (tags) {
                for (var beacon of tags) {
                    if (beacon.realBatteryStatusValue && beacon.realBatteryStatusValue != -1) {
                        if (beacon.realBatteryStatusValue < process.env.VUE_APP_TAG_MIN_BATTERY_LEVEL_LOW && beacon.realBatteryStatusValue >= process.env.VUE_APP_TAG_MIN_BATTERY_LEVEL_CRITICAL) { // process.env.VUE_TAG_MIN_BATTERY_LEVEL_LOW = 50 and process.env.VUE_TAG_MIN_BATTERY_LEVEL_CRITICAL = 30
                            this.isTagBatteryLow = true;
                        } else if (beacon.realBatteryStatusValue < process.env.VUE_APP_TAG_MIN_BATTERY_LEVEL_CRITICAL) { // process.env.VUE_TAG_MIN_BATTERY_LEVEL_CRITICAL = 30
                            this.isTagBatteryCritical = true;
                        }
                    }
                    if (beacon.tagType && beacon.tagType.reference === "OAL-BT") {
                        this.isBeaconOALBTExists = true;
                    }
                }

            }
        },

        currentSite: function(site) {
            console.log("Component(Siteoverview)::watch(currentSite) called with :", site);
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentSite",
            "user",
            "siteBuildings",
            "siteIndoorAreas",
            "siteGeofences",
            "siteWalls",
            "siteBLEGateways",
            "siteTags",
            "siteGeonotifications",
            "totalNumberOfFloors",
            "siteAutoCalibrationTags",
            "siteAssets",
            "hasAccess",
            "hasFullConfigAccess"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "createUserSite",
            "getSiteById",
            "getSiteBuildingsAndTheirFloors",
            "getSiteIndoorAreas",
            "getSiteGeofences",
            "getSiteWalls",
            "getSiteBLEGateways",
            "getSiteBLEGateways",
            "getSiteTags",
            "getSiteAutoCalibrationTags",
            "getSiteGeonotifications",
            "getSiteAssets",
            "resetBuildingsState",
            "resetIndoorAreasState",
            "resetGeofencesState",
            "resetWallsState",
            "resetGatewaysState",
            "resetTagsState",
            "resetAutocalibrationTagsState",
            "resetAssetsState",
            "resetSitesState"
        ])
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitemetadata": SiteMetadata,
        "app-countersoverview": SiteCountersOverview,
        "app-sitetitle": SiteTitle,
        "app-checklist": CheckList,
        "app-markerlocation": MarkerLocation,
        "app-siteactionrequired": SiteActionRequired
    },
    filters: {}
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
