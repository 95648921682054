<template>
    <div id="siteMetadataPortlet" class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" id="Path-11" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                            <rectfill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                        </g>
                    </svg>
                </span>
                <h3 class="kt-portlet__head-title">
                    {{ $t("site_siteInformationsTitle") }}
                    <small>{{ $t("site_siteInformationsTitleSmall") }}</small>
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <router-link v-if="hasFullConfigAccess(siteId)" tag="button" :to="{ name: 'editsite', params: { siteId: this.$route.params.siteId } }" id="siteMetadata_editSite" class="btn btn-outline-brand btn-sm btn-icon">
                    <i class="la la-pencil"></i>
                </router-link>
            </div>
        </div>

        <div class="kt-portlet__body">
            <div class="kt-widget28">
                <div class="kt-widget28__wrapper kt-portlet__space-x">
                    <!-- begin::Tab Content -->
                    <div class="tab-content kt-margin-t-0">
                        <div id="menu11" class="tab-pane active">
                            <div class="kt-widget28__tab-items">
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("site_siteName") }}</span>
                                    <span class="wordWrap" id="siteMetadata_name">{{ this.pSite ? this.pSite.name : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("site_siteType") }}</span>
                                    <span class="wordWrap" id="siteMetadata_type">{{ this.pSite ? this.pSite.siteType.isDefault ? $t(this.pSite.siteType.name) : this.pSite.siteType.name : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("gtw_gatewayType") }}</span>
                                    <span class="wordWrap" id="siteMetadata_type">{{ this.pSite ? $t(this.pSite.BLEGatewaysType) : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("site_siteDescription") }}</span>
                                    <span class="wordWrap" id="siteMetadata_description">{{ this.pSite ? this.pSite.description : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("common_street") }}</span>
                                    <span class="wordWrap" id="siteMetadata_street">{{ this.pSite ? this.pSite.street : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("common_city") }}</span>
                                    <span class="wordWrap" id="siteMetadata_city">{{ this.pSite ? this.pSite.city : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("common_postCode") }}</span>
                                    <span class="wordWrap" id="siteMetadata_zipCode">{{ this.pSite ? this.pSite.zipCode : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("common_state") }}</span>
                                    <span class="wordWrap" id="siteMetadata_state">{{ this.pSite ? this.pSite.state : "" }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("common_country") }}</span>
                                    <span class="wordWrap" id="siteMetadata_country">{{ this.countryName }}</span>
                                </div>
                                <div class="kt-widget28__tab-item">
                                    <span>{{ $t("common_timezone") }}</span>
                                    <span class="wordWrap" id="siteMetadata_timezone">{{ this.pSite ? this.pSite.timezone : "" }}</span>
                                </div>
                                <div v-if="this.isSuperAdmin()" class="kt-widget28__tab-item">
                                    <span>{{ $t("site_siteEngine") }}</span>
                                    <span class="wordWrap" id="siteMetadata_engineMode">{{ this.pSite ? $t(this.pSite.engineMode) : "" }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Tab Content -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import lookup from "country-code-lookup";

export default {
    props: ["pSite"],
    data() {
        return {
            siteId: this.$route.params.siteId
        };
    },
    created: function() {
        console.log("Component(SiteMetadata)::created() - called with id: " + this.siteId);
    },
    mounted: function() {
        console.log("Component(SiteMetadata)::mounted() - Init metronic layout");
    },
    destroyed: function() {
        console.log("Component(SiteMetadata)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        pSite: function(site) {
            console.log("Component(SiteMetadata)::pSite() - watch : ", site);
            // Add defense
            if (site && site.id !== this.siteId) {
                alert("Site identifier (" + site.id + ") does't match to URL parameter");
                this.$router.back();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["hasFullConfigAccess"]),

        countryName() {
            if (this.pSite) {
                let countryData = lookup.byIso(this.pSite.country);
                if (countryData && countryData.country) {
                    return countryData.country;
                }
            }
            return "";
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        //...mapActions()
        isSuperAdmin() {
            if (this.$store.getters.user) {
                return this.$store.getters.user.isSuperAdmin
            }
            return false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.wordWrap {
    word-wrap: break-word;
}
</style>
