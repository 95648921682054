<template>
    <div
        class="modal fade show"
        id="gatewayswhichemit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalHelpPlacement" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="this.gateways.length == 0" class="modal-title kt-font-danger">{{ $t("Site_emptyGatewaysEmission") }}</h5>
                    <h5 v-else class="modal-title kt-font-danger">{{ $t("main_gatewayscoverage") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClickOkButton"></button>
                </div>
                <div class="modal-body">
                    <!--begin emptyList component-->
                    <app-emptylist targetObjectName="GATEWAYSEMISSIONMODAL_LIST" mainTextKey="Site_emptyGatewaysEmission" subTextKey="Site_emptyGatewaysEmissionSubLabel" imgName="empty_gateway.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                    <!--end emptyList component-->
                    <div v-if="this.gateways.length !== 0">
                        <p>{{ $t("main_gatewayscoverageSubText1") }} <u class="kt-font-danger">{{ $t("main_gatewayscoverageSubText11") }}</u></p>
                        <p>{{ $t("main_gatewayscoverageSubText2") }} <u>{{ $t("main_gatewayscoverageSubText22") }}</u></p>
                        <p>{{ $t("main_gatewayscoverageSubText3") }}</p>
                    </div>
                    <!--begin: Datatable -->
                    <table id="gatewaysCoverage_datatable" align="center" class="table table-bordered table-hover" style="margin-top: 10px;"  v-show="!isEmptyList">
                        <thead class="thead-light">
                            <tr>
                                <th>
                                    {{ $t("troubleshooting_gatewayNameLabel") }}
                                </th>
                                <th>
                                    {{ $t("troubleshooting_gatewayLastEmissionLabel") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="gateway in tagGatewaysCoverage" :key="gateway.id">
                                <td>{{ gateway.name }}</td>
                                <td>
                                    {{ gateway.lastEmission }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!--end: Datatable -->
                </div>
                <div class="modal-footer">
                    <button
                        id="okButton"
                        type="button"
                        class="btn btn-dark"
                        data-dismiss="modal"
                        @click="onClickOkButton"
                    >{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import EmptyList from "./../utils/emptylist.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            tagGatewaysCoverage: null,
            isEmptyList: false
        };
    },
    props: {
        gateways: {
            type: Array
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        gateways: function(gateways) {
            console.log("Component(gatewayswhichemitmodal)::watch(gateways) called with :", gateways);
            this.tagGatewaysCoverage = [];
            if (gateways.length === 0) {
                this.isEmptyList = true;
            } else {
                this.isEmptyList = false;
                for (var gateway of gateways) {
                    if (gateway.lastEmission != -1) {
                        var lastEmission = moment(gateway.lastEmission).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                        lastEmission += " (" + moment(gateway.lastEmission).fromNow() + ")";
                        gateway.lastEmission = lastEmission;
                    } else {
                        gateway.lastEmission = "-";
                    }
                    this.tagGatewaysCoverage.push(gateway);
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["resetShowGatewaysEmissionModal"]),
        
        onClickOkButton() {
            this.resetShowGatewaysEmissionModal();
        }
    }
};
</script>