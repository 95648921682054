<template>
    <div v-if="hasAccess(siteId, 'SITE_CONFIGURATION.BUILDING_AND_FLOOR')" class="kt-portlet kt-portlet--fit kt-portlet--head-lg kt-portlet--head-overlay kt-portlet--skin-solid kt-portlet--height-fluid">
        <div class="kt-portlet__head kt-portlet__head--noborder kt-portlet__space-x">
            <div class="kt-portlet__head-label">
                <h3 id="countersOverview_siteName" class="kt-portlet__head-title siteNameCustom">
                    {{ this.pSite ? this.pSite.name : "" }}
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="kt-widget17">
                <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides imageSite" v-bind:style="{ backgroundImage: 'url(' + this.siteImageURL + ')' }">
                    <div class="kt-widget17__chart" style="height:320px;">
                        <div class="chartjs-size-monitor">
                            <div class="chartjs-size-monitor-expand"><div class=""></div></div>
                            <div class="chartjs-size-monitor-shrink"><div class=""></div></div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget17__stats">

                    <div class="kt-widget17__items">
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.BUILDING_AND_FLOOR')" :to="{ name: 'buildings' }" tag="div" id="btViewBuildingsPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                            fill="#000000"
                                        ></path>
                                        <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1"></rect>
                                        <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3"></path>
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("b&f_buildingsAndFloors") }}</span>
                            <span id="countersOverview_numberOfBuildingsAndFloors" class="kt-widget17__desc">{{ buildingsAndFloorsStr }}</span>
                        </router-link>
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.INDOOR_AREA')" :to="{ name: 'indoorAreas' }" tag="div" id="btViewIndoorAreasPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M8.08113883,20 L15.9188612,20 C16.5645068,20 17.137715,19.5868549 17.3418861,18.9743416 L19.6721428,11.9835717 C19.8694432,11.3916705 19.6797482,10.7394436 19.1957765,10.3456849 L12.9561839,5.26916104 C12.4053757,4.82102426 11.6158052,4.82050247 11.0644052,5.26791085 L4.80622561,10.345825 C4.32117072,10.7394007 4.13079092,11.3923728 4.32832067,11.984962 L6.65811388,18.9743416 C6.86228495,19.5868549 7.43549322,20 8.08113883,20 Z" id="Path-4" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("area_indoorAreas") }}</span>
                            <span id="countersOverview_numberOfIndoorAreas" class="kt-widget17__desc">{{ indoorAreasStr }}</span>
                            <span v-if="totalAreaString !== 0" id="countersOverview_totalArea" class="kt-widget17__desc">{{ $t("area_totalArea") }} <span style="color: #2dbb87;">{{ totalAreaString }}{{ $t("common_sqM") }}</span></span>
                        </router-link>
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.GATEWAY')" :to="{ name: 'gateways' }" tag="div" id="btViewGatewaysPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                        <path d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z" id="Oval-21" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("gtw_bleGateways") }}</span>
                            <span id="countersOverview_numberOfGateways" class="kt-widget17__desc">{{ bleGatewaysStr }}</span>
                        </router-link>
                    </div>

                    <div class="kt-widget17__items">

                        <router-link v-if="hasAccess(siteId, 'ASSET_PROVISIONING.TAG_MANAGEMENT')" :to="{ name: 'tags' }" tag="div" id="btViewTagsPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                        <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("tag_bleTags") }}</span>
                            <span id="countersOverview_numberOfTags" class="kt-widget17__desc">{{ bleTagsStr }}</span>
                        </router-link>
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.AUTOCALIBRATION_TAG')" :to="{ name: 'autocalibrationtags' }" tag="div" id="btViewAutoCalibrationTagsPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                        <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("act_autocalibrationtags") }}</span>
                            <span id="countersOverview_numberOfAutocalibrationTags" class="kt-widget17__desc">{{ actTagsStr }}</span>
                        </router-link>
                        <router-link v-if="hasAccess(siteId, 'ASSET_PROVISIONING.ASSET_MANAGEMENT')" :to="{ name: 'assets' }" tag="div" id="btViewAssetsPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z" fill="#000000" opacity="0.3" transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) " />
                                        <path d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z" fill="#000000" transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) " />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("asset_assets") }}</span>
                            <span id="countersOverview_numberOfAssets" class="kt-widget17__desc">{{ assetsStr }}</span>
                        </router-link>
                    </div>

                    <div class="kt-widget17__items kt-margin-b-30">
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.GEOFENCE')" :to="{ name: 'geofences' }" tag="div" id="btViewGeofencesPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3" />
                                        <path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("geofence_geofences") }}</span>
                            <span id="countersOverview_numberOfGeofences" class="kt-widget17__desc">{{ geofencesStr }}</span>
                        </router-link>
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.NOTIFICATION')" :to="{ name: 'geonotifications' }" tag="div" id="btViewNotificationsPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z" fill="#000000" />
                                        <rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("geo_notif_notifications") }}</span>
                            <span id="countersOverview_numberOfNotifications" class="kt-widget17__desc">{{ notificationsStr }}</span>
                        </router-link>
                        <router-link v-if="hasAccess(siteId, 'SITE_CONFIGURATION.WALL')" :to="{ name: 'walls' }" tag="div" id="btViewWallsPage" class="kt-widget17__item">
                            <span class="kt-widget17__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <path d="M12,3 C12.5522847,3 13,3.44771525 13,4 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,4 C11,3.44771525 11.4477153,3 12,3 Z M12,8 C12.5522847,8 13,8.44771525 13,9 L13,10 C13,10.5522847 12.5522847,11 12,11 C11.4477153,11 11,10.5522847 11,10 L11,9 C11,8.44771525 11.4477153,8 12,8 Z M12,13 C12.5522847,13 13,13.4477153 13,14 L13,15 C13,15.5522847 12.5522847,16 12,16 C11.4477153,16 11,15.5522847 11,15 L11,14 C11,13.4477153 11.4477153,13 12,13 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4477153,21 11,20.5522847 11,20 L11,19 C11,18.4477153 11.4477153,18 12,18 Z" fill="#000000" />
                                        <path d="M21,9.04031242 L21,14.9596876 C21,15.23583 20.7761424,15.4596876 20.5,15.4596876 C20.3864643,15.4596876 20.276309,15.4210472 20.1876525,15.350122 L16.488043,12.3904344 C16.272412,12.2179296 16.2374514,11.9032834 16.4099561,11.6876525 C16.433022,11.6588201 16.4592107,11.6326315 16.488043,11.6095656 L20.1876525,8.64987802 C20.4032834,8.47737324 20.7179296,8.51233393 20.8904344,8.7279649 C20.9613596,8.81662142 21,8.92677668 21,9.04031242 Z M3,14.9596876 L3,9.04031242 C3,8.76417005 3.22385763,8.54031242 3.5,8.54031242 C3.61353575,8.54031242 3.723691,8.5789528 3.81234752,8.64987802 L7.51195699,11.6095656 C7.72758796,11.7820704 7.76254865,12.0967166 7.59004388,12.3123475 C7.56697799,12.3411799 7.54078935,12.3673685 7.51195699,12.3904344 L3.81234752,15.350122 C3.59671656,15.5226268 3.28207037,15.4876661 3.1095656,15.2720351 C3.03864038,15.1833786 3,15.0732233 3,14.9596876 Z" fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                            </span>
                            <span class="kt-widget17__subtitle">{{ $t("wall_walls") }}</span>
                            <span id="countersOverview_numberOfWalls" class="kt-widget17__desc">{{ wallStr }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "../../../i18n";
export default {
    name: "countersoverview",
    props: ["pSite", "pSiteBuildings", "pSiteIndoorAreas", "pSiteBLEGateways", "pSiteGeofences", "pSiteWalls", "pSiteTags", "pTotalNumberOfFloors", "pSiteAutoCalibrationTags", "pSiteAssets", "pSiteNotifications"],
    data() {
        return {
            basePath: process.env.VUE_APP_BACKEND_URL,
            siteId: this.$route.params.siteId,
            siteImageURL: ""
        };
    },
    created: function() {
        let siteId = this.$route.params.siteId;
        console.log("Component(CountersOverview)::created() - called with id: " + siteId);
    },
    mounted: function() {
        console.log("Component(CountersOverview)::mounted() - Init metronic layout");
    },
    destroyed: function() {
        console.log("Component(CountersOverview)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        pSite: function(siteData) {
            if (siteData){
                console.log("Component(CountersOverview)::watch(pSite) - called with : ", siteData);
                if (siteData.image) {
                    const siteImage = siteData.image;
                    if (siteImage.includes("data:", 0) && siteImage.includes("base64")) {
                        // Old image (not yet uploaded)
                        this.siteImageURL = siteImage;
                    } else {
                        this.siteImageURL = this.basePath + siteImage;
                    }
                } else {
                    // Set default image
                    this.siteImageURL = "/assets/ale/banner_home.png";
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["hasAccess"]),

        totalAreaString: function() {
            let total = 0;
            if (this.pSiteIndoorAreas && this.pSiteIndoorAreas.length > 0) {
                for (let indoorArea of this.pSiteIndoorAreas){
                    total += indoorArea.area;
                }
            }
            return total;
        },

        buildingsAndFloorsStr: function() {
            let str = "";
            if (this.pSiteBuildings) {
                if (this.pSiteBuildings.length == 0 || this.pSiteBuildings.length == 1) {
                    str += this.pSiteBuildings.length + " " + i18n.t("b&f_building") + " (" + i18n.t("common_withATotalOf");
                } else {
                    str += this.pSiteBuildings.length + " " + i18n.t("b&f_buildings") + " (" + i18n.t("common_withATotalOf");
                }

                if (this.pTotalNumberOfFloors == 0 || this.pTotalNumberOfFloors == 1) {
                    str += " " + this.pTotalNumberOfFloors + " " + i18n.t("b&f_floor") + ")";
                } else {
                    str += " " + this.pTotalNumberOfFloors + " " + i18n.t("b&f_floors") + ")";
                }
            } else {
                str += "0 " + i18n.t("b&f_building");
            }
            return str;
        },

        indoorAreasStr: function() {
            let str = "";
            if (this.pSiteIndoorAreas) {
                if (this.pSiteIndoorAreas.length == 0 || this.pSiteIndoorAreas.length == 1) {
                    str += this.pSiteIndoorAreas.length + " " + i18n.t("area_indoorArea");
                } else {
                    str += this.pSiteIndoorAreas.length + " " + i18n.t("area_indoorAreas");
                }
            } else {
                str += "0 " + i18n.t("area_indoorArea");
            }
            return str;
        },

        geofencesStr: function() {
            let str = "";
            if (this.pSiteGeofences) {
                if (this.pSiteGeofences.length == 0 || this.pSiteGeofences.length == 1) {
                    str += this.pSiteGeofences.length + " " + i18n.t("geofence_geofence");
                } else {
                    str += this.pSiteGeofences.length + " " + i18n.t("geofence_geofences");
                }
            } else {
                str += "0 " + i18n.t("geofence_geofence");
            }
            return str;
        },

        notificationsStr: function() {
            let str = "";
            if (this.pSiteNotifications) {
                if (this.pSiteNotifications.length == 0 || this.pSiteNotifications.length == 1) {
                    str += this.pSiteNotifications.length + " " + i18n.t("geo_notif_notification");
                } else {
                    str += this.pSiteNotifications.length + " " + i18n.t("geo_notif_notifications");
                }
            } else {
                str += "0 " + i18n.t("geo_notif_notification");
            }
            return str;
        },

        wallStr: function() {
            let str = "";
            if (this.pSiteWalls) {
                if (this.pSiteWalls.length == 0 || this.pSiteWalls.length == 1) {
                    str += this.pSiteWalls.length + " " + i18n.t("wall_wall");
                } else {
                    str += this.pSiteWalls.length + " " + i18n.t("wall_walls");
                }
            } else {
                str += "0 " + i18n.t("wall_wall");
            }
            return str;
        },

        bleGatewaysStr: function() {
            let str = "";
            if (this.pSiteBLEGateways) {
                if (this.pSiteBLEGateways.length == 0 || this.pSiteBLEGateways.length == 1) {
                    str += this.pSiteBLEGateways.length + " " + i18n.t("gtw_bleGateway");
                } else {
                    str += this.pSiteBLEGateways.length + " " + i18n.t("gtw_bleGateways");
                }
            } else {
                str += "0 " + i18n.t("gtw_bleGateway");
            }
            return str;
        },

        bleTagsStr: function() {
            let str = "";
            if (this.pSiteTags) {
                if (this.pSiteTags.length == 0 || this.pSiteTags.length == 1) {
                    str += this.pSiteTags.length + " " + i18n.t("tag_bleTag");
                } else {
                    str += this.pSiteTags.length + " " + i18n.t("tag_bleTags");
                }
            } else {
                str += "0 " + i18n.t("tag_bleTag");
            }
            return str;
        },

        actTagsStr: function() {
            let str = "";
            if (this.pSiteAutoCalibrationTags) {
                if (this.pSiteAutoCalibrationTags.length == 0 || this.pSiteAutoCalibrationTags.length == 1) {
                    str += this.pSiteAutoCalibrationTags.length + " " + i18n.t("act_autocalibrationtag");
                } else {
                    str += this.pSiteAutoCalibrationTags.length + " " + i18n.t("act_autocalibrationtags");
                }
            } else {
                str += "0 " + i18n.t("act_autocalibrationtag");
            }
            return str;
        },

        assetsStr: function() {
            let str = "";
            if (this.pSiteAssets) {
                if (this.pSiteAssets.length <= 1) {
                    str += this.pSiteAssets.length + " " + i18n.t("asset_asset");
                } else {
                    str += this.pSiteAssets.length + " " + i18n.t("asset_assets");
                }
            } else {
                str = "0 " + i18n.t("asset_assets");
            }
            return str;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal"])
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.imageSite {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.siteNameCustom {
    background: #5e78ff !important;
    padding: 4px 10px !important;
    color: white !important;
    border-radius: 5px !important;
}
</style>
