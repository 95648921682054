<template>
    <div id="siteMetadataPortlet" class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                    >
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 Z M14.1654881,7.35483745 L9.61055177,10.3622525 C9.47921741,10.4489666 9.39637436,10.592455 9.38694497,10.7495509 L9.05991526,16.197949 C9.04337012,16.4735952 9.25341309,16.7104632 9.52905936,16.7270083 C9.63705011,16.7334903 9.74423017,16.7047714 9.83451193,16.6451626 L14.3894482,13.6377475 C14.5207826,13.5510334 14.6036256,13.407545 14.613055,13.2504491 L14.9400847,7.80205104 C14.9566299,7.52640477 14.7465869,7.28953682 14.4709406,7.27299168 C14.3629499,7.26650974 14.2557698,7.29522855 14.1654881,7.35483745 Z"
                                fill="#000000"
                            />
                        </g>
                    </svg>
                </span>
                <h3 class="kt-portlet__head-title">{{ $t("site_siteLocation") }}</h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <router-link
                    v-if="this.pSite.location && this.hasSubscription && !this.isSubscriptionTerminated && hasAccess(siteId, 'ASSET_SEARCH.SEARCH')"
                    :to="{ name: 'assetsTrackingDashboard' }"
                    tag="a"
                    class="btn btn-brand btn-bold btn-sm paddingTop8"
                >
                    <i class="la la-map-marker marginTMinus4"></i>
                    {{ $t("map_seeRessourcesMap") }}
                </router-link>
            </div>
        </div>

        <div class="kt-portlet__body">
            <div class="kt-widget28">
                <div class="kt-widget28__wrapper">
                    <!-- begin::Tab Content -->
                    <app-locationmap mode="markerLocation"></app-locationmap>
                    <!-- end::Tab Content -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LocationMap from "../../map/locationmap.vue";

export default {
    props: ["pSite"],
    data() {
        return {
            siteId: this.$route.params.siteId
        };
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["isSubscriptionTerminated", "hasSubscription",  "hasAccess"])
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-locationmap": LocationMap
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.wordWrap {
    word-wrap: break-word;
}
.paddingTop8 {
    padding-top: 8px;
}
.marginTMinus4 {
    margin-top: -4px;
}
</style>
